<script setup lang="ts">
import api from "@/modules/api";
import TheButton from "../TheButton.vue";
import { ref } from "vue";

const send = ref(false);

const sendEmail = () => {
  if (send.value) return;
  send.value = true;
  api.post("/email/verify/notification");
};
const checkEmail = () => {
  // Opens the default email app with Gmail as one option
  window.location.href = "mailto:";
};
</script>

<template>
  <div class="text-sm bg-orange-100 flex p-2 text-left items-center justify-between">
    <div class="flex items-center space-x-1 text-orange-500">
      <Icon name="ExclamationCircleIcon" class="w-6 h-6 text-red-500" />
      You must verify your account before continue.
    </div>
    <TheButton type="outline" v-if="!send" @click="sendEmail"
      >Verify now</TheButton
    >
    <span v-else class="p-2 text-orange-600" @click="checkEmail">Check email</span>
  </div>
</template>
