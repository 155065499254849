import { defineStore } from "pinia";
import api from "@/modules/api";
import { ref, computed } from "vue";

export const useUserStore = defineStore("userStore", () => {
  const user = ref(null);
  const isAuthenticated = computed(() => !!user.value);

  async function fetchUser() {
    try {
      const response = await api.post(
        `${import.meta.env.VITE_API_URL}/auth/account`
      );
      user.value = response.data;
    } catch (error) {
      user.value = null;
    }
  }

  async function getUser() {
    if (user.value) {
      return user.value;
    } else {
      await fetchUser();
      return user.value;
    }
  }

  function setUser(userData: any) {
    user.value = userData;
  }

  function clearUser() {
    user.value = null;
  }

  return { user, fetchUser, getUser, setUser, clearUser, isAuthenticated };
});
