// stores/categoryStore.js
import { defineStore } from 'pinia'
import api from "@/modules/api"

export const useCategoryStore = defineStore('categoryStore', {
  state: () => ({
    categories: [],         // Array to store the list of categories
    loading: false,         // Boolean to track loading state
    error: null,            // To store any errors
  }),

  getters: {
    // Return categories sorted alphabetically
    sortedCategories: (state) => {
      return [...state.categories].sort((a, b) => a.name.localeCompare(b.name))
    },
    // Return categories matching a specific search term
    filterCategories: (state) => {
      return (term) => state.categories.filter(category =>
        category.name.toLowerCase().includes(term.toLowerCase())
      )
    }
  },

  actions: {
    // Fetch categories from API
    async fetchCategories() {
      this.loading = true
      this.error = null
      try {
        const response = await api.get('/categories')
        this.categories = response.data
      } catch (error) {
        this.error = error.message || 'Failed to load categories'
      } finally {
        this.loading = false
      }
    },

    // Fetch categories only if not already loaded
    async getCategories() {
      if (this.categories.length === 0) {
        await this.fetchCategories()
      }
      return this.categories
    }
  }
})
