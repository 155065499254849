<template>
  <div class="border-t border-gray-700 bg-black">
    <div class="m-container text-white footer py-16">
      <div class="border-b border-gray-700 items-start justify-between py-16 flex flex-col md:flex-row">
        <div>
          <img src="/text-logo.png" class="invert h-10" />
          <h2 class="text-3xl libre-font">It's all about connections!</h2>
          <p class="text-lg mt-6 max-w-lg">
            We're here to make your travel experience unforgettable, making
            memories with locals and fellow adventurers.
          </p>
        </div>
        <div class="flex items-center space-x-4 invert">
          <a href="https://instagram.com/meetontrip" class="w-7">
            <img src="/images/icons/instagram.svg" alt="connect on instagram" />
          </a>
          <a href="https://facebook.com/meetontrip" class="w-7">
            <img src="/images/icons/facebook.svg" alt="connect on instagram" />
          </a>
          <a href="https://linkedin.com/company/meetontrip" class="w-7">
            <img src="/images/icons/linkedin.svg" alt="connect on instagram" />
          </a>
        </div>
      </div>
      <div
        class="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-6 items-center space-x-2"
      >
        <a
          href="https://instagram.com/meetontrip"
          target="_blank"
          rel="noreferrer"
        >
          <!-- <img src="/icons/icon-instagram.svg" alt="connect on instagram" /> -->
        </a>
      </div>
      <div
        class="pt-8 flex flex-col md:flex-row space-y-4 items-center justify-between"
      >
        <div
          class="mt-4 md:mt-0 flex space-x-1.5 font-semibold md:space-x-4 text-xs"
        >
          <a href="/page/about" class="text-white">Story</a>
          <a href="/page/terms" class="text-white">Terms & conditions</a>
          <a href="/page/help" class="text-white">Support</a>
          <a href="/page/privacy" class="text-white">Privacy</a>
        </div>
        <div>© MeetOnTrip 2024</div>
      </div>
    </div>
  </div>
</template>
