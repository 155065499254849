import { createRouter, createWebHistory } from "vue-router";
import { useUserStore } from '@/stores/user';

function requireAuth(routes: any) {
  return routes.map((route: { meta: any; }) => ({
    ...route,
    meta: { requiresAuth: true, ...(route.meta || {}) },
  }));
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [

    {
      path: "/register",
      name: "auth.register",
      component: () => import("@/components/Auth/Register.vue"),
    },
    {
      path: "/login",
      name: "auth.login",
      component: () => import("@/components/Auth/Register.vue"),
    },
    ...requireAuth([
      {
        path: "/",
        name: "index",
        component: () => import("@/components/Home/pages/index.vue"),
      },
      {
        path: "/home",
        name: "home",
        component: () => import("@/components/HomeScreen.vue"),
      },
      {
        path: "/add",
        name: "add",
        component: () => import("@/components/AddScreen.vue"),
      },

      {
        path: "/onboarding",
        name: "auth.onboarding",
        component: () => import("@/components/Auth/Onboarding.vue"),
      },
      {
        path: "/invites",
        name: "auth.invites",
        component: () => import("@/components/Auth/Invites.vue"),
      },

      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/DashboardView.vue"),
      },
      {
        name: "start",
        path: "/conversation/start",
        component: () => import("@/components/Chat/StartChat.vue"),
      },
      {
        name: "conversation",
        path: "/conversation/:conversation_id",
        component: () => import("@/components/Chat/Conversation.vue"),
      },
      {
        name: "conversations",
        path: "/conversations",
        component: () => import("@/components/Chat/Conversations.vue"),
      },
      {
        path: "/notifications",
        name: "notifications.index",
        component: () => import("@/components/Notification/Notifications.vue"),
      },
      {
        path: "/account",
        name: "auth.account",
        component: () => import("@/components/Auth/Account.vue"),
      },
      {
        path: "/@:username",
        name: "auth.profile",
        component: () => import("@/components/Auth/Profile.vue"),
      },
      {
        path: "/trips/:city_name?/:city_id?",
        name: "trip.index",
        component: () => import("@/components/Trip/TripsList.vue"),
      },
      {
        path: "/trip/create",
        name: "trip.create",
        component: () => import("@/components/Trip/Add.vue"),
      },
      {
        path: "/meet/create/:userId?/:username?",
        name: "meet.create",
        component: () => import("@/components/Meet/Create.vue"),
      },
      {
        path: "/meet/:id",
        name: "meet.show",
        component: () => import("@/components/Meet/ShowMeet.vue"),
      },
      //users
      {
        path: "/users",
        name: "users",
        component: () => import("@/components/Auth/Users.vue"),
      },
      {
        path: "/page/:pagename",
        name: "page",
        component: () => import('@/components/Home/pages/index.vue'), // Fallback component if needed
        props: true,
        // @ts-ignore for now
        beforeEnter: (to, from, next) => {
          const { pagename } = to.params;
          import(`@/components/Home/pages/${pagename}.vue`)
            .then((module) => {
              to.matched[0].components.default = module.default;
              next();
            })
            .catch((error) => {
              console.error("Error loading component:", error);
              next(false); // Cancel navigation if component import fails
            });
        }
      }
      

    ]),
    {
      path: "/:pathMatch(.*)*",
      name: "404",
      component: () => import("@/components/404.vue"),
    }
  ],
});

router.beforeEach(async (to, from, next) => {
  const userStore = useUserStore();

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    await userStore.getUser(); // Ensure user information is fetched

    if (userStore.isAuthenticated) {
      //redirect from index, login and register to home
      if (to.path === "/" || to.path === "/login" || to.path === "/register") {
        next({ name: "home" });
        return;
      } else {
        next();
        return;
      }
    }
    //if (userStore.isAuthenticated) {
    //next('/'); // Redirect to login if not authenticated
    //} else {
    //  next(); // Proceed if authenticated
  }
  //} else {
  next();
  // }
});

export default router;
