// HeroIconPlugin.js
import { h } from "vue";
import * as HeroIconsOutline from "@heroicons/vue/24/outline";

export default {
  install: (app) => {
    // Add all icons to the app component
    app.config.globalProperties.$HeroIcons = HeroIconsOutline;

    // Create a global component for dynamic icon rendering
    app.component("Icon", {
      props: {
        name: {
          type: String,
          required: true,
        },
        class: {
          type: String,
          default: "h-6 w-6 text-blue-500",
        },
      },
      render() {
        const icon = this.$HeroIcons[this.name];
        return icon ? h(icon, { class: this.class }) : null;
      },
    });
  },
};
