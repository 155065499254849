<template>
  <nav :class="[props.isStatic ? '' : 'text-white ', 'relative z-10 ']">
    <div class="m-container py-7 px-3 md:px-0 flex flex-wrap items-center justify-between">
      <div class="flex items-center space-x-2">
        <a href="/" class="flex items-center space-x-3 rtl:space-x-reverse">
          <img
            src="/text-logo.png"
            :class="[props.isStatic ? 'invert' : '', 'h-8 ']"
            alt="Meetontrip Logo"
          />
        </a>
      </div>
      <button
        data-collapse-toggle="navbar-default"
        @click="visible = !visible"
        type="button"
        class="md:hidden"
        aria-controls="navbar-default"
        aria-expanded="false"
        :class="props.isStatic ? 'text-white' : ''"
      >
        <span class="sr-only">Open main menu</span>
        <svg
          class="w-5 h-5"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 17 14"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M1 1h15M1 7h15M1 13h15"
          />
        </svg>
      </button>
      <div
        class="absolute md:relative font-medium transition-all z-[-1] md:z-10 bg-black bg-opacity-50 md:bg-transparent pb-8 top-0 pt-16 px-6 left-0 md:p-0 w-full md:block md:w-auto"
        :class="visible ? 'block' : 'hidden'"
        id="navbar-default"
      >
        <ul
          class="uppercase tracking-tight text-black flex flex-col mt-4 md:flex-row md:space-x-8 md:space-y-0 space-y-4 md:mt-0 md:border-0 dark:border-gray-700"
        >
          <li>
            <a
              href="/page/mission"
              :class="['link', props.isStatic ? '' : 'invert']"
              >Mission</a
            >
          </li>
          <li>
            <a
              href="/page/about"
              :class="['link', props.isStatic ? '' : 'invert']"
              >Story</a
            >
          </li>
          <li>
            <a
              href="/page/faq"
              :class="['link', props.isStatic ? '' : 'invert']"
              >Support</a
            >
          </li>
          <li class="w-full block">
            <a
              href="/register"
              :class="['m-button', props.isStatic ? 'invert' : '']"
              >Get Started</a
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script setup>
import { ref } from "vue";
const props = defineProps({ isStatic: Boolean });
const visible = ref(false);
</script>

<style scoped>
.link {
  @apply capitalize tracking-tighter font-semibold text-md text-white;
}
</style>
