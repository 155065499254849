<template>
  <div
    :class="[
      !compact
        ? 'relative w-32 h-32 rounded-full aspect-square'
        : 'rounded-full w-10 h-10 bg-primary',
      userAvatar? ` bg-cover` : 'bg-cover bg-primary',
    ]"
    :style="userAvatar ? `background-image: url(${userAvatar})`: 'background-image: url(/images/avatar.webp);'"
  >
    <div v-if="!compact" class="absolute bottom-4 left-4 text-left w-full">
      <slot>
      </slot>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  avatarUrl: String,
  name: String,
  compact: {
    type: Boolean,
    default: false,
  },
  subtext: {
    type: String,
  },
});

const userAvatar = computed(() => {
  return props.avatarUrl
    ? import.meta.env.VITE_APP_URL + props.avatarUrl
    : undefined;
});
</script>
