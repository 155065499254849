import axios from "axios";
axios.defaults.headers.common["API-Key"] = import.meta.env.VITE_API_KEY;
// Create an Axios instance
const api = axios.create({
  baseURL: import.meta.env.VITE_API_URL, // Replace with your actual API URL
});

// Add a request interceptor to include the token in every request
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("access_token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// Add a response interceptor to handle token expiration
api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      return;
      originalRequest._retry = true;

      try {
        const response = await api.post("/auth/refresh");
        const { access_token } = response.data;

        // Store the new token
        localStorage.setItem("access_token", access_token);

        // Update the Authorization header
        originalRequest.headers["Authorization"] = `Bearer ${access_token}`;

        // Retry the original request with the new token
        return api(originalRequest);
      } catch (err) {
        // Handle the refresh token failure (e.g., logout user)
        console.error("Token refresh failed", err);
        return Promise.reject(err);
      }
    }

    return Promise.reject(error);
  },
);

export default api;
